import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

import IconButton from "@material-ui/core/IconButton";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import LinearProgress from "@material-ui/core/LinearProgress";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import Tooltip from "@material-ui/core/Tooltip";
import SettingsIcon from "@material-ui/icons/Settings";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import TabletIcon from '@material-ui/icons/Tablet';
import PrintIcon from '@material-ui/icons/Print';
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaCreditCard } from 'react-icons/fa';
import { IoPrint } from 'react-icons/io5';
import { RiBankLine } from "react-icons/ri";
import { FaUber } from 'react-icons/fa';
import API from 'util/API';

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const RestaurantToolbar = (props) => {
  const { className, proxy, ...rest } = props;

  const classes = useStyles();
  
  const [buttonLoading, setButtonLoading] = useState(false);
  const restaurant = useSelector(
    (state) => state.restaurant.selectedRestaurant
  );

 
  if (!restaurant) {
    return null;
  }

// console.log(JSON.stringify(restaurant));


const handleUberOAuth = async () => {
  try {
    setButtonLoading(true);
    const response = await API.get(`/uber/auth-url?restaurantId=${restaurant.urlIdentifier}`);
      
    
    if (!response.data) {
      throw new Error('Failed to get Uber auth URL');
    }
    
    const { authUrl } = response.data;
    window.open(authUrl, '_blank', 'width=600,height=800');
  } catch (error) {
    console.error('Error getting Uber auth URL:', error);
    // You may want to add error handling UI here
  } finally {
    setButtonLoading(false);
  }
};




  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {buttonLoading ? <LinearProgress /> : null}
      <div className={classes.row}>
        <span className={classes.spacer} />

         {/* <Button variant="filled" style={{ backgroundColor: green[700] }} onClick={openAdyenSettings}>Adyen</Button> */}

         <NavLink to="#" onClick={handleUberOAuth}>
  <Tooltip title="Connect Uber Eats">
    <IconButton aria-label="uber eats integration">
      <FaUber />
    </IconButton>
  </Tooltip>
</NavLink>
        <NavLink to="/grantOffers" >
          <Tooltip title="Adyen Capital">
            <IconButton aria-label="Adyen Capital">
            <RiBankLine />
              
            </IconButton>
          </Tooltip>
        </NavLink>
    

        <NavLink to="/subscriptions" >
          <Tooltip title="Subscriptions">
            <IconButton aria-label="service subscriptions">
              <SubscriptionsIcon />
            </IconButton>
          </Tooltip>
        </NavLink>

        <NavLink to="/paymentTypes" >
          <Tooltip title="Payment types">
            <IconButton aria-label="Payment types">
              <FaCreditCard />
              
            </IconButton>
          </Tooltip>
        </NavLink>

        <NavLink to="/surchargeSettings" >
          <Tooltip title="Surcharge Settings">
            <IconButton aria-label="Surcharges">
              <CreditCardIcon />
            </IconButton>
          </Tooltip>
        </NavLink>

        <NavLink to="/posSettings" className={classes.textLink}>
          <Tooltip title="POS Settings">
            <IconButton aria-label="POS settings">
              <TabletIcon />
            </IconButton>
          </Tooltip>
        </NavLink>

        <NavLink to="/posPrinterSettings" className={classes.textLink}>
          <Tooltip title="POS Printers Settings">
            <IconButton aria-label="POS Printers settings">
              <IoPrint />
            </IconButton>
          </Tooltip>
        </NavLink>

        <NavLink to="/posPrintSettings" className={classes.textLink}>
          <Tooltip title="POS Printing Settings">
            <IconButton aria-label="POS Printing settings">
              <PrintIcon />
            </IconButton>
          </Tooltip>
        </NavLink>

        <NavLink to="/restaurantSettings" className={classes.textLink}>
          <Tooltip title="Online Settings">
            <IconButton aria-label="online settings">
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </NavLink>

        <NavLink to="/serviceBellTypes" className={classes.textLink}>
          <Tooltip title="Service Bell Settings">
            <IconButton aria-label="service bell">
              <RoomServiceIcon />
            </IconButton>
          </Tooltip>
        </NavLink>

        <NavLink to="/openHours" className={classes.textLink}>
          <Tooltip title="Open Hours Settings">
            <IconButton aria-label="open hours">
              <AccessTimeIcon />
            </IconButton>
          </Tooltip>
        </NavLink>

        <NavLink to={"/user"} className={classes.textLink}>
          <Tooltip title="Restaurant Managers">
            <IconButton aria-label="add restaurant manager">
              <PersonAddIcon />
            </IconButton>
          </Tooltip>
        </NavLink>

        <NavLink
          to={`/qrcodes/${props.restaurant.urlIdentifier}`}
          className={classes.textLink}
        >
          <Tooltip title="QR Code">
            <IconButton aria-label="qr code">
              <AssignmentReturnedIcon />
            </IconButton>
          </Tooltip>
        </NavLink>
       
      </div>
    </div>
  );
};

RestaurantToolbar.propTypes = {
  className: PropTypes.string,
};

export default RestaurantToolbar;
