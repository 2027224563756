import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination
} from '@material-ui/core';

//import { getInitials } from 'helpers';
import { useHistory } from 'react-router-dom';
import { setOrderDetail } from 'store/actions/restaurant';
import { useSelector, useDispatch } from 'react-redux';
import { transformOrderId } from 'util/orderUtils'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
   // minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  textLink: {
		textDecoration: 'underline',
	},
}));

const OrdersTable = props => {
  const { className, orders, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);

  const showOrderDetail = (order) => dispatch(setOrderDetail(order));
  const user = useSelector((state) => state.account.user);
  const currencySymbol = (user && user.currencySymbol) ? user.currencySymbol : "$";


  const handleSelectAll = event => {
    const { orders } = props;

    let selectedRestaurants;

    if (event.target.checked) {
      selectedRestaurants = orders.map(order => order.id);
    } else {
      selectedRestaurants = [];
    }

    setSelectedOrders(selectedRestaurants);
  };

  const handleSelectOne = (event, urlIdentifier) => {
    const selectedIndex = selectedOrders.indexOf(urlIdentifier);
    let newSelectedOrders = [];

    if (selectedIndex === -1) {
      newSelectedOrders = newSelectedOrders.concat(selectedOrders, urlIdentifier);
    } else if (selectedIndex === 0) {
      newSelectedOrders = newSelectedOrders.concat(selectedOrders.slice(1));
    } else if (selectedIndex === selectedOrders.length - 1) {
      newSelectedOrders = newSelectedOrders.concat(selectedOrders.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedOrders = newSelectedOrders.concat(
        selectedOrders.slice(0, selectedIndex),
        selectedOrders.slice(selectedIndex + 1)
      );
    }

    setSelectedOrders(newSelectedOrders);
    props.selectorders(newSelectedOrders);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const getOrderTotal = (order) => {
    const total = order.order.items.filter((item) => item.dish.isDeletedDish !== true).reduce((count, curItem) => {
      return count + curItem.quantity * curItem.subTotal;
  }, 0);
  return total.toFixed(2).toString();
  };

  const orderCreateTime = (order) => {
    if (order && order.createdAt) {
      if (order.createdAt && typeof order.createdAt === 'object' && '_seconds' in order.createdAt) {
        return  moment(new Date(order.createdAt._seconds * 1000)).format('DD/MM/YYYY HH:mm:ss')
      }
      return moment(order.createdAt).format('DD/MM/YYYY HH:mm:ss')
    }
    return 'N/A';
  };

  const openOrderDetail = (e, order) => {
    showOrderDetail(order);
    history.push('/orderDetail');
  };

  const isOrderRefunded = (order) => {
    if (order && order.payment && order.payment.amount_refunded) {
      return 'YES';
    }
    if (order && order.payment && order.payment.result_code === 'FULL_REFUND'){
       return 'YES';
    }
    if (order && order.tillRefunds && order.tillRefunds.length > 0){
      return 'YES';
   }

   if (order && order.adyenRefunds && order.adyenRefunds.length > 0){
    return 'YES';
 }

 if (order && order.chinaPaymentsRefunds && order.chinaPaymentsRefunds.length > 0){
  return 'YES';
}

    if (order.isRefunded) {
      if (order.isRefunded === true) {
        return 'YES';
      } else {
          for (var dish in order.dishes) {
             if (dish.isRefunded === true) {
               return 'YES';
             }
          }
          return 'NO';
      }
      
    } else {
      return 'NO';
    }
  }

  const paymentStatus = (order) => {
    if (order && order.payment && order.payment.status) {
      return order.payment.status;
    }
    if (order && order.payment && order.payment.result_code) {
      return order.payment.result_code;
    }
    if (order && order.payment && order.payment.eventCode) {
      return order.payment.eventCode;
    }

    if (order && order.payment && order.payment.orderStatus) {
      return order.payment.orderStatus;
    }

    return 'N/A';
  }
 

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedOrders.length === orders.length}
                      color="primary"
                      indeterminate={
                        selectedOrders.length > 0 &&
                        selectedOrders.length < orders.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Order Id</TableCell>
                  <TableCell>Created Time</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Table</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Payment Status</TableCell>
                  <TableCell>Refunded</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders && orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(order => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={order.id}
                    selected={selectedOrders.indexOf(order.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedOrders.indexOf(order.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, order.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                     
                      <Button onClick={e => openOrderDetail(e, order)}>
                        <div className={classes.textLink}>
                          <Typography variant="body1">{transformOrderId(order.id)}</Typography>
                        </div>
                        </Button>
                    
                    </TableCell>
                    <TableCell>{orderCreateTime(order)}</TableCell>
                    <TableCell>
                      {order.user ? order.user.name : ''}
                    </TableCell>
                    <TableCell>{order.restaurant && order.restaurant.tableId}</TableCell>
                    <TableCell>{order.status}</TableCell>
                    <TableCell>{paymentStatus(order)}</TableCell>
                    <TableCell>{isOrderRefunded(order)}</TableCell>
                    <TableCell>
                       {currencySymbol}{getOrderTotal(order)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </TableContainer>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={orders.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[50, 100]}
        />
      </CardActions>
    </Card>
  );
};

OrdersTable.propTypes = {
  className: PropTypes.string,
  restaurants: PropTypes.array.isRequired
};

export default OrdersTable;
