import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
// import { DataGrid } from '@mui/x-data-grid';
import SearchBar from "material-ui-search-bar";
import { Alert } from "@material-ui/lab";
import PrintIcon from "@material-ui/icons/Print";
import red from "@material-ui/core/colors/red";
import {
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Chip,
  List,
  ListItem,
  Grid,
} from "@material-ui/core";

//import { getInitials } from 'helpers';
import { useHistory } from "react-router-dom";
import {
  setTransactionDetail,
  setTransactionPage,
  setTransactionIds,
} from "store/actions/transaction";
import { useDispatch, useSelector } from "react-redux";

import { setOrderDetail } from "store/actions/restaurant";
import { transformOrderId } from "util/orderUtils";
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  textLink: {
    textDecoration: "underline",
  },
}));

const TransactionsTable = (props) => {
  const { className, transactions, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [keyword, setKeyword] = useState("");


  const updateTransactionDetail = (transaction) =>
    dispatch(setTransactionDetail(transaction));
  const updateTransactionIds = (ids) => dispatch(setTransactionIds(ids));
  const showOrderDetail = (order) => dispatch(setOrderDetail(order));
  const setPage = (page) => dispatch(setTransactionPage(page));
  const page = useSelector((state) => state.transaction.page);
  const user = useSelector((state) => state.account.user);
  const currencySymbol = (user && user.currencySymbol) ? user.currencySymbol : "$";

  const handleSelectOne = (event, transactionId) => {
    const selectedIndex = selectedTransactions.indexOf(transactionId);
    let newSelectedTransactions = [];

    if (selectedIndex === -1) {
      newSelectedTransactions = newSelectedTransactions.concat(
        selectedTransactions,
        transactionId
      );
    } else if (selectedIndex === 0) {
      newSelectedTransactions = newSelectedTransactions.concat(
        selectedTransactions.slice(1)
      );
    } else if (selectedIndex === selectedTransactions.length - 1) {
      newSelectedTransactions = newSelectedTransactions.concat(
        selectedTransactions.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedTransactions = newSelectedTransactions.concat(
        selectedTransactions.slice(0, selectedIndex),
        selectedTransactions.slice(selectedIndex + 1)
      );
    }

    setSelectedTransactions(newSelectedTransactions);
    updateTransactionIds(newSelectedTransactions);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const getTimeString = (date) => {
    if (typeof date === "object" && "_seconds" in date) {
      return moment(new Date(date._seconds * 1000)).format(
        "DD/MM/YYYY HH:mm:ss"
      );
    }
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  };

  const getTimeString2 = (date) => {
    if (!date) {
      return "";
    }
    // console.log('date', date);
    if (`${date}`.includes("/")) {
      return date;
    }

    if (typeof date === "object" && "_seconds" in date) {
      return moment(new Date(date._seconds * 1000)).format(
        "DD/MM/YYYY HH:mm:ss"
      );
    }
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  };

  const openTransactionDetail = (e, transaction) => {
    updateTransactionDetail(transaction);
    history.push("/transaction");
  };

  const transferLocalDishes = (dishes) => {
    let newDishes = [];
    dishes.forEach((dish) => {
      newDishes.push({
        dish: dish,
        quantity: dish.quantity,
        subTotal: dish.subTotal,
        specialString: dish.specialString,
      });
    });
    return newDishes;
  };

  const transferLocalOrder = (order) => {
    if (!order) {
      return;
    }

    const restaurant = { tableId: order.tableNumber };
    const newOrder = {
      id: order.orderNumber,
      createdAt: order.createdAt,
      status: order.status,
      restaurant: restaurant,
      uuid: order.uuid,
      order: {
        items: transferLocalDishes(order.dishes),
      },
      user: {
        email: order.customerEmail,
        phoneNumber: order.customerPhone,
        name: order.customerName,
        postcode: order.customerPostcode,
      },
    };
    return newOrder;
  };

  const openOrderDetail = (e, order) => {
    showOrderDetail(transferLocalOrder(order));
    history.push(`/orderDetail/${order.orderNumber}`);
  };

  const getPaymentTypes = (transaction) => {
    if (!transaction) {
      return "";
    }
    if (transaction.deliveryPartnerType && transaction.deliveryPartnerType !== '' && !transaction.deliveryPartnerPostpaid) {
      return transaction.deliveryPartnerType;
    }

    //console.log('transaction.operationHistory', transaction.operationHistory, transaction.paymentTypes);
    if (transaction.paymentTypes && transaction.paymentTypes.length > 0) {
      // remove duplicates paymentType
      const paymentTypes = [
        ...new Set(
          transaction.paymentTypes.map((paymentType) => paymentType.type)
        ),
      ];

      return paymentTypes.join(",");
    } else {
      if (
        transaction.operationHistory &&
        transaction.operationHistory.length > 0
      ) {
        let filterOperations = transaction.operationHistory.filter(
          (operation) => operation.operationType === "Payment"
        );
        return filterOperations
          .map((operation) => operation.operationName)
          .join(",");
      }
    }
    return "";
  };

  const filterTransactions = transactions.filter((transaction) => {
    if (keyword === "") {
      return true;
    }
    const lowcaseKey = keyword.toLocaleLowerCase();
    if (transaction.uuid.toLowerCase().includes(lowcaseKey)) {
      return true;
    }
    if (transaction.operationString.toLowerCase().includes(lowcaseKey)) {
      return true;
    }

    if (transaction.tableNo.toLowerCase().includes(lowcaseKey)) {
      return true;
    }

    if (getPaymentTypes(transaction).toLowerCase().includes(lowcaseKey)) {
      return true;
    }

    return false;
  });

  const calculateTransactionTotal = () => {
    let total = 0;
    transactions.forEach((tran) => {
      if (selectedTransactions.includes(tran.uuid)) {
        total += Number(tran.totalSpending);
      }
    });
    return total.toFixed(2);
  };


  const downloadCSV = () => {
    const rows = filterTransactions.map((transaction) => {
      return {
        "Transaction ID": transaction.uuid && transaction.uuid.length > 6 ? transaction.uuid.substr(transaction.uuid.length - 6) : transaction.uuid,
        "Table No": transaction.tableNo,
        "Total": transaction.totalSpending.toFixed(2),
        "Payment Types": getPaymentTypes(transaction),
        "Payment Time": getTimeString2(transaction.payTime),
      };
    });

    const csv = Papa.unparse(rows);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'transaction-report.csv');
  };

  const downloadPaymentTypesCSV = () => {
    const rows = filterTransactions.flatMap((transaction) => {
      const transactionId = transaction.uuid && transaction.uuid.length > 6 ? transaction.uuid.substr(transaction.uuid.length - 6) : transaction.uuid;
      const paymentTypes = transaction.paymentTypes.map((paymentType) =>  {
      return {
        "Transaction ID": transactionId,
        "Payment Type": paymentType.type,
        "Payment Amount": paymentType.paymentAmount.toFixed(2),
        "Merchant Amount": paymentType.merchantAmount.toFixed(2),
        "Payment Time": getTimeString2(paymentType.time),
      };
    });
      
      return paymentTypes;
    });

    const csv = Papa.unparse(rows);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'transaction-types-report.csv');
  };

  const downloadAdjustmentsCSV = () => {
    const rows = filterTransactions.flatMap((transaction) => {
      const transactionId = transaction.uuid && transaction.uuid.length > 6 ? transaction.uuid.substr(transaction.uuid.length - 6) : transaction.uuid;
      const paymentTypes = transaction.adjustments.map((adjustment) =>  {
      return {
        "Transaction ID": transactionId,
        "Adjustment Type": adjustment.adjustmentType,
        "Adjustment Amount": Number(adjustment.price).toFixed(2),
        "Adjustment Time": getTimeString2(adjustment.timeCreated),
      };
    });
      
      return paymentTypes;
    });

    const csv = Papa.unparse(rows);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'adjustment-types-report.csv');
  };

  const downloadDishesCSV = () => {
    const rows = filterTransactions.flatMap((transaction) => {
      const transactionId = transaction.uuid && transaction.uuid.length > 6 ? transaction.uuid.substr(transaction.uuid.length - 6) : transaction.uuid;
      const dishes = transaction.orders.flatMap((order) => {
        const orderNumber = order.orderNumber;
        const orderDishes = order.dishes.map((dish) => {
          return {
            "Transaction ID": transactionId,
            "Order Number": orderNumber,
            "Dish Name": dish.name,
            "Dish Price": Number(dish.price).toFixed(2),
            "Dish Quantity": dish.quantity,
            "Dish Subtotal": Number(dish.subTotal).toFixed(2),
          };
        });
        return orderDishes;
      });
      return dishes;
    });

    const csv = Papa.unparse(rows);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'dishes-report.csv');
  };


  return (
    <Grid lg={12} md={12} xl={12} xs={12}>
      {selectedTransactions.length > 0 ? (
        <Paper>
          <Alert severity="info">{`Selected transactions total amount: $${calculateTransactionTotal()}`}
          </Alert>
        </Paper>
      ) : null}
      {filterTransactions && filterTransactions.length > 0 ? (
        <div style={{ paddingBottom: "1rem" }}>
         
            <Button variant="outlined" color="primary" onClick={downloadCSV} style={{ marginRight: '1rem' }} >
              Download Transactions CSV
            </Button>
            <Button variant="outlined" color="primary" onClick={downloadPaymentTypesCSV} style={{ marginRight: '1rem' }}>
              Download Payment Types CSV
            </Button>

            <Button variant="outlined" color="primary" onClick={downloadAdjustmentsCSV} >
              Download Adjustment Types CSV
            </Button>

            <Button variant="outlined" color="primary" onClick={downloadDishesCSV} style={{ marginRight: '1rem' }}>
              Download Dishes CSV
            </Button>
      
        </div>
      ) : null}

      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          {/* <PerfectScrollbar> */}
          <div className={classes.inner} >
            <SearchBar
              value={keyword}
              onChange={(newValue) => setKeyword(newValue)}
              onRequestSearch={() => console.log(keyword)}
            />

            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      {/* <Checkbox
                      checked={selectedTransactions.length === transactions.length}
                      color="primary"
                      indeterminate={
                        selectedTransactions.length > 0 &&
                        selectedTransactions.length < transactions.length
                      }
                      onChange={handleSelectAll}
                    /> */}
                    </TableCell>
                    <TableCell>Orders</TableCell>
                    <TableCell>Updated Time</TableCell>
                    <TableCell>Payment Time</TableCell>
                    <TableCell>Table</TableCell>
                    <TableCell>Transaction Id</TableCell>
                    <TableCell>Payment Type</TableCell>
                    {/* <TableCell>Operations</TableCell> */}
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions &&
                    filterTransactions
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((transaction) => (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={transaction.uuid}
                          selected={
                            selectedTransactions.indexOf(transaction.uuid) !==
                            -1
                          }
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={
                                selectedTransactions.indexOf(
                                  transaction.uuid
                                ) !== -1
                              }
                              color="primary"
                              onChange={(event) =>
                                handleSelectOne(event, transaction.uuid)
                              }
                              value="true"
                            />
                          </TableCell>

                          <TableCell>
                            <List dense disablePadding>
                              {transaction.orders
                                .filter((order) => !order.permanentDeletion)
                                .map((order) => (
                                  <ListItem key={order.orderNumber}>
                                    <Button
                                      onClick={(e) => openOrderDetail(e, order)}
                                    >
                                      <div className={classes.textLink}>
                                        <Typography variant="body1">
                                          {transformOrderId(order.orderNumber)}
                                        </Typography>
                                      </div>
                                    </Button>
                                  </ListItem>
                                ))}
                            </List>
                          </TableCell>

                          <TableCell>
                            {getTimeString(transaction.updatedAt)}
                          </TableCell>
                          <TableCell>
                            {getTimeString2(transaction.payTime)}
                          </TableCell>

                          <TableCell width="5%">
                            {transaction.tableNo}
                          </TableCell>

                          <TableCell>
                            <Button
                              onClick={(e) =>
                                openTransactionDetail(e, transaction)
                              }
                            >
                              <div className={classes.textLink}>
                                <Typography variant="body1">
                                  {
                                    // get last 6 letters of uuid
                                    transaction.uuid && transaction.uuid.length > 6 ? transaction.uuid.substr(transaction.uuid.length - 6) : transaction.uuid

                                  }
                                </Typography>
                             

                              </div>
                            </Button>
                            <br />
                            {transaction && transaction.isInvoicePrinted === true ? (
                              <Chip
                                size="small"
                                label="Invoice Printed"
                                icon={<PrintIcon />}
                                style={{ backgroundColor: red[200] }}
                                className={classes.marginRightBottom}
                              />
                            ) : null}
                               {transaction.paymentTypes.map((paymentType) => {
                                 if (paymentType.reference === 'staff manual to mark payments as paid') {
                                   return (
                                     <Chip
                                       key={paymentType.reference}
                                       size="small"
                                       label="Mark Paid by staff"
                                       style={{ backgroundColor: red[100], marginRight: '4px', marginBottom: '4px' }}
                                     />
                                   );
                                 }
                                 return null;
                               })}
                          </TableCell>
                          <TableCell width="10%">
                            {getPaymentTypes(transaction)}
                          </TableCell>


                          <TableCell>
                          {currencySymbol}{transaction.totalSpending.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* </PerfectScrollbar> */}
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={filterTransactions.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </Grid>
  );
};

TransactionsTable.propTypes = {
  className: PropTypes.string,
  transactions: PropTypes.array.isRequired,
};

export default TransactionsTable;
