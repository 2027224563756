import React, { useState, useEffect, useRef } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import clsx from "clsx";
import {
  Card,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Typography,
  CardContent,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { MenuToolbar } from "./components";
import { useSelector, useDispatch } from "react-redux";

import { authMiddleWare } from "util/auth";
import { useHistory } from "react-router-dom";
import {
  saveMenu,
  clearAPIStatus,
  getMyMenu,
  updateMenu,
} from "store/actions/restaurantMenu";
import {
  showSuccessSnackbar,
  showErrorSnackbar,
} from "store/actions/snackbarMessage";
import API from "util/API";
import { CSVLink } from "react-csv";


const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },

  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: "#f7f7f7",
  },
  content: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
  },
  inner: {
    minWidth: 1050,
  },
  leftContainer: {
    flex: 1,
    alignItems: "left",
  },
  rightContainer: {
    display: "flex",
    alignItems: "right",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  uploadButton: {
    marginLeft: "4px",
    margin: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
  textLink: {
    textDecoration: "underline",
  },
}));

const Menu = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const groupCSVLinkRef = useRef();
  const itemCSVLinkRef = useRef();

  const dispatch = useDispatch();
  const history = useHistory();
  const [buttonLoading, setButtonLoading] = useState(false);
  const saveTheMenu = (menu) => dispatch(saveMenu(menu));
  const updateTheMenu = (menu, menuId) => dispatch(updateMenu(menu, menuId));
  const clearApiStatus = () => dispatch(clearAPIStatus());
  const loadMenu = (menuId) => dispatch(getMyMenu(menuId));
  const [fileError, setFileError] = useState(null);
  const [csvFile, setCsvFile] = useState(null);
  const [content, setContent] = useState(null);
  const [groups, setGroups] = useState([]);
  
  const [groupsDownloaded, setGroupsDownloaded] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsDownloaded, setItemsDownloaded] = useState(false);
  const showError = (msg) => dispatch(showErrorSnackbar(msg));
  const showSuccess = (msg) => dispatch(showSuccessSnackbar(msg));

  const error = useSelector((state) => state.restaurantMenu.err);
  const apiSuccess = useSelector((state) => state.restaurantMenu.apiSuccess);
  const menu = useSelector((state) => state.restaurantMenu.menu);

  const menuId = props.match.params.menuId;

  const handleFileChange = (event) => {
    setCsvFile(event.target.files[0]);
  };

  const setIsForHubster = (value) => {
     setValues({ ...values, isForHubster: value });
  }

  const fileHandler = async (event, type) => {
    event.preventDefault();
    if (csvFile === null) {
      showError("Please choose a csv file.");
      return;
    }
    setButtonLoading(true);

    let formData = new FormData();
    formData.append("file", csvFile);
    formData.append("content", content);
    try {
      await API.post(`/uploadMenu/${menuId}/${type}`, formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      setButtonLoading(false);
      showSuccess("Uploaded successfully");
      window.location.reload();
    } catch (error) {
      if (error && error.response && error.response.status === 403) {
        history.push("/login");
      }
      console.log(error);
      setButtonLoading(false);
      setFileError("Error in posting the data");
      showError("Something went wrong, please try again.");
    }
  };

  useEffect(() => {
    if (groups.length > 0 && groupsDownloaded === true) {
      groupCSVLinkRef.current.link.click();
    }
  }, [groups, groupsDownloaded]);


  useEffect(() => {
    if (items.length > 0 && itemsDownloaded === true) {
      itemCSVLinkRef.current.link.click();
    }
  }, [items, itemsDownloaded]);

  const downloadMenu = async (event, type) => {
    event.preventDefault();
    setButtonLoading(true);

    try {
      const response = await API.get(`/downloadMenuCSV/${menuId}/${type}`);

      setButtonLoading(false);
      setItemsDownloaded(false);
      setGroupsDownloaded(false);

      console.log(response.data);
      if (type === "group") {
        if (response.data && response.data.allGroups) {
          setGroups(response.data.allGroups);
          setGroupsDownloaded(true);
        }
      }

      if (response.data && response.data.allItems) {
        setItems(response.data.allItems);
        setItemsDownloaded(true);
      }
      // console.log(done);
      // done();
    } catch (error) {
      if (error && error.response && error.response.status === 403) {
        history.push("/login");
      }
      console.log(error);
      setButtonLoading(false);
      showError("Something went wrong, please try again.");
    }
  };

  if (error && error !== null) {
    clearApiStatus();
  }

  if (apiSuccess) {
    clearApiStatus();
    history.push("/myMenus");
  }

  useEffect(() => {
    if (!menu || !menuId) {
      return;
    }
    setValues({ ...menu });
  }, [menu, menuId]);

  useEffect(() => {
    const menuId = props.match.params.menuId;
    if (menuId && menuId.length > 0) {
      loadMenu(menuId);
    }
  }, [props]);

  const initialState = {
    menuName: "",
    menuDescription: "",
    isMainMenu: false,
    isSecondaryMenu: false,
    isThirdMenu: false,
    isFourthMenu: false,
    isFifthMenu: false,
    isDefault: false,
    isForHubster: false,
    qrCodeType: "",
  };

  const [values, setValues] = useState(initialState);

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value, type } = e.target;
    //console.log('name', name, value, type);
    if (type === "checkbox") {
      setValues({ ...values, [name]: !values[name] });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleMainMenuChange = (e) => {
    if (e.target.checked) {
      setValues({
        ...values,
        isSecondaryMenu: false,
        isMainMenu: true,
        isThirdMenu: false,
        isFourthMenu: false,
        isFifthMenu: false,
      });
    } else {
      setValues({ ...values, isMainMenu: false });
    }
  };

  const handleSecondaryMenuChange = (e) => {
    if (e.target.checked) {
      setValues({
        ...values,
        isSecondaryMenu: true,
        isMainMenu: false,
        isThirdMenu: false,
        isFourthMenu: false,
        isFifthMenu: false,
      });
    } else {
      setValues({ ...values, isSecondaryMenu: false });
    }
  };

  const handleThirdMenuChange = (e) => {
    if (e.target.checked) {
      setValues({
        ...values,
        isSecondaryMenu: false,
        isMainMenu: false,
        isThirdMenu: true,
        isFourthMenu: false,
        isFifthMenu: false,
      });
    } else {
      setValues({ ...values, isThirdMenu: false });
    }
  };

  const handleFourthMenuChange = (e) => {
    if (e.target.checked) {
      setValues({
        ...values,
        isSecondaryMenu: false,
        isMainMenu: false,
        isThirdMenu: false,
        isFourthMenu: true,
        isFifthMenu: false,
      });
    } else {
      setValues({ ...values, isFourthMenu: false });
    }
  };

  const handleFifthMenuChange = (e) => {
    if (e.target.checked) {
      setValues({
        ...values,
        isSecondaryMenu: false,
        isMainMenu: false,
        isThirdMenu: false,
        isFourthMenu: false,
        isFifthMenu: true,
      });
    } else {
      setValues({ ...values, isFifthMenu: false });
    }
  };

  const handleQRCodeTypeChange = (event) => {
    setValues({ ...values, qrCodeType: event.target.value });
  };

  const saveMyMenu = () => {
    authMiddleWare(props.history);

    if (menuId && menuId.length > 0) {
      updateTheMenu(values, menuId);
    } else {
      saveTheMenu(values);
    }
  };

  const uploadFantuanMenu = async () => {
   

    if (menuId && menuId.length > 0) {
      try {
        setButtonLoading(true);
         await API.post(`/uploadFantuanMenu/${menuId}`);
        showSuccess("Menu uploaded successfully");
        setButtonLoading(false);

      } catch (error) {
        if (error && error.response && error.response.status === 403) {
          history.push("/login");
        }
        console.log(error);
        setButtonLoading(false);
        showError("Something went wrong, please try again.");
      }
  
    } else {
      console.error("invalid menu Id");
      showError("Something went wrong, please try again.");
    }
  };

  const uploadUberEatsMenu = async () => {
    if (menuId && menuId.length > 0) {
      try {
        setButtonLoading(true);
        await API.post(`/uploadUberEatsMenu/${menuId}`);
        showSuccess("Menu uploaded successfully to UberEats");
        setButtonLoading(false);
      } catch (error) {
        if (error && error.response && error.response.status === 403) {
          history.push("/login");
        }
        console.log(error);
        setButtonLoading(false);
        showError("Something went wrong, please try again.");
      }
    } else {
      console.error("invalid menu Id");
      showError("Something went wrong, please try again.");
    }
  };

  return (
    <Grid className={clsx(classes.root, className)}>
      {menu ? <MenuToolbar menuId={menuId} menu={menu} /> : null}

      <Card className={classes.content}>
        <CardContent>
          <PerfectScrollbar>
            <Grid container spacing={3}>
              <Grid item md={5} xs={10}>
                <TextField
                  fullWidth
                  label="Menu name"
                  margin="dense"
                  name="menuName"
                  variant="outlined"
                  value={values.menuName}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item md={5} xs={10}>
                <TextField
                  fullWidth
                  label="Menu Description"
                  margin="dense"
                  name="menuDescription"
                  variant="outlined"
                  value={values.menuDescription}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={values.isMainMenu}
                      onChange={(e) => handleMainMenuChange(e)}
                      name="isMainMenu"
                    />
                  }
                  label="Main Menu"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={values.isSecondaryMenu}
                      onChange={(e) => handleSecondaryMenuChange(e)}
                      name="isSecondaryMenu"
                    />
                  }
                  label="Secondary Menu"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={values.isThirdMenu}
                      onChange={(e) => handleThirdMenuChange(e)}
                      name="isThirdMenu"
                    />
                  }
                  label="Third Menu"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={values.isFourthMenu}
                      onChange={(e) => handleFourthMenuChange(e)}
                      name="isFourthMenu"
                    />
                  }
                  label="Fourth Menu"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      checked={values.isFifthMenu}
                      onChange={(e) => handleFifthMenuChange(e)}
                      name="isFifthMenu"
                    />
                  }
                  label="Fifth Menu"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                    checked={values.isForHubster}
                    onChange={(e) => setIsForHubster(e.target.checked)}
                    name="isForHubster"
                    color="primary"
                  />
                  }
                  label="Hubster Menu"
                />
              </Grid>

              <Grid item md={12} xs={12} xl={12}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: 150 }}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    QR CODE TYPE
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={values.qrCodeType}
                    onChange={handleQRCodeTypeChange}
                    label="qrCodeType"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"pickup-delivery"}>
                      pickup-delivery
                    </MenuItem>
                    <MenuItem value={"takeaway"}>takeaway</MenuItem>
                    <MenuItem value={"pickup"}>pickup</MenuItem>
                    <MenuItem value={"delivery"}>delivery</MenuItem>
                    <MenuItem value={"foodcourt"}>foodcourt</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {menuId ? (
                <Grid item md={12} xs={12} xl={12}>
                  <div>
                    <Typography gutterBottom variant="h4">
                      Upload menu
                    </Typography>

                    {fileError ? (
                      <div className={classes.customError}>
                        {" "}
                        Wrong forat Format || Supported Format is csv
                      </div>
                    ) : null}
                    <input type="file" onChange={handleFileChange} />
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      size="small"
                      startIcon={<CloudUploadIcon />}
                      className={classes.uploadButton}
                      onClick={(event) => fileHandler(event, "group")}
                    >
                      1. Upload menu group csv
                      {buttonLoading && (
                        <CircularProgress
                          size={30}
                          className={classes.progess}
                        />
                      )}
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      size="small"
                      startIcon={<CloudUploadIcon />}
                      className={classes.uploadButton}
                      onClick={(event) => fileHandler(event, "item")}
                    >
                      2. Upload menu items csv
                      {buttonLoading && (
                        <CircularProgress
                          size={30}
                          className={classes.progess}
                        />
                      )}
                    </Button>
                  </div>
                </Grid>
              ) : null}

              <Grid item md={12} xs={12} xl={12}>
                <div>
                  <Typography gutterBottom variant="h4">
                    Download menu
                  </Typography>

                  <CSVLink
                    data={groups}
                    ref={groupCSVLinkRef}
                    filename={`${menuId}-groups.csv`}
                  ></CSVLink>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<CloudDownloadIcon />}
                    className={classes.uploadButton}
                    onClick={(e) => downloadMenu(e, "group")}
                  >
                    1. Download menu group csv
                    {buttonLoading && (
                      <CircularProgress size={30} className={classes.progess} />
                    )}
                  </Button>

                  <CSVLink
                    data={items}
                    filename={`${menuId}-items.csv`}
                    ref={itemCSVLinkRef}
                  ></CSVLink>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<CloudDownloadIcon />}
                    className={classes.uploadButton}
                    onClick={(e) => downloadMenu(e, "item")}
                  >
                    2. Download menu items csv
                    {buttonLoading && (
                      <CircularProgress size={30} className={classes.progess} />
                    )}
                  </Button>
                </div>
              </Grid>
              <Grid item container md={6} xs={12}>
                <Grid item md={3} xs={6}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.submitButton}
                    onClick={saveMyMenu}
                    disabled={!values.menuName}
                  >
                    Save Menu
                    {buttonLoading && (
                      <CircularProgress size={30} className={classes.progess} />
                    )}
                  </Button>
                </Grid>

                <Grid item md={4} xs={6}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.submitButton}
                    onClick={uploadFantuanMenu}
                    disabled={buttonLoading || !values.menuName}
                  >
                    Upload to Fantuan
                    {buttonLoading && (
                      <CircularProgress size={30} className={classes.progess} />
                    )}
                  </Button>
                </Grid>

                <Grid item md={4} xs={6}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.submitButton}
                    onClick={uploadUberEatsMenu}
                    disabled={buttonLoading || !values.menuName}
                  >
                    Upload to UberEats
                    {buttonLoading && (
                      <CircularProgress size={30} className={classes.progess} />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </PerfectScrollbar>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Menu;
