import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  MenuItem,
} from "@material-ui/core";
import AddressField from "components/AddressField";


const AdyenTrustDialog = ({ open, onClose, onSubmit }) => {
  const [formValues, setFormValues] = useState({
    city: "",
    country: "",
    postalCode: "",
    stateOrProvince: "",
    street: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    email: "",
    identificationData: {
      cardNumber: "",
      type: "driversLicense",
      issuerState: "",
      number: "",
    },
  });

  // const australianStates = [
  //   'New South Wales',
  //   'Victoria',
  //   'Queensland',
  //   'South Australia',
  //   'Western Australia',
  //   'Tasmania',
  //   'Northern Territory',
  //   'Australian Capital Territory'
  // ];

  const [errors, setErrors] = useState({});

  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log("formValues", formValues);

    const errors = {};

    if (!formValues.city) {
      errors.city = "City is required";
    }

    if (!formValues.country) {
      errors.country = "Country is required";
    }

    if (!formValues.postalCode) {
      errors.postalCode = "Postal code is required";
    }

    if (!formValues.street) {
      errors.street = "Street is required";
    }

    if (!formValues.firstName) {
      errors.firstName = "First name is required";
    }

    if (!formValues.lastName) {
      errors.lastName = "Last name is required";
    }

    if (!formValues.dateOfBirth) {
      errors.dateOfBirth = "Date of birth is required";
    } else if (!/^\d{4}-\d{2}-\d{2}$/.test(formValues.dateOfBirth)) {
      errors.dateOfBirth = "Date of birth must be in YYYY-MM-DD format";
    }

    if (!formValues.email) {
      errors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValues.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!formValues.identificationData.type) {
      errors.identificationData = {
        ...errors.identificationData,
        type: "Required",
      };
    }

    if (formValues.identificationData.type === "driversLicense") {
      if (!formValues.identificationData.cardNumber) {
        errors.identificationData = {
          ...errors.identificationData,
          cardNumber: "Required",
        };
      }

      if (!formValues.identificationData.issuerState) {
        errors.identificationData = {
          ...errors.identificationData,
          issuerState: "Required",
        };
      }

      if (!formValues.identificationData.number) {
        errors.identificationData = {
          ...errors.identificationData,
          number: "Required",
        };
      }
    } else if (formValues.identificationData.type === "passport") {
      if (!formValues.identificationData.number) {
        errors.identificationData = {
          ...errors.identificationData,
          number: "Required",
        };
      }
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    onSubmit(formValues);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    if (name.startsWith("identificationData.")) {
      if (
        name === "identificationData.number" &&
        formValues.identificationData.type === "passport"
      ) {
        setFormValues((prevValues) => ({
          ...prevValues,
          identificationData: {
            number: value,
            type: "passport",
          },
        }));
      } else {
        setFormValues((prevValues) => ({
          ...prevValues,
          identificationData: {
            ...prevValues.identificationData,
            [name.substring("identificationData.".length)]: value,
          },
        }));
      }
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleFormCancel = () => {
    setFormValues({
      city: "",
      country: "",
      postalCode: "",
      street: "",
      firstName: "",
      lastName: "",
      stateOrProvince: "",
      dateOfBirth: "",
      email: "",
      identificationData: {
        type: "driversLicense",
        cardNumber: "",
        issuerState: "",
        number: "",
      },
    });

    setErrors({});

    onClose();
  };

  const handleAddressChange = (newAddress) => {
    setFormValues({
      ...formValues,
      city: newAddress.city,
      country: newAddress.countryCode,
      postalCode: newAddress.postalCode,
      street: newAddress.line1,
      stateOrProvince: newAddress.stateOrProvinceCode,
    });
  };

  const handleIdentityTypeChange = (event) => {
    const { value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      identificationData: {
        cardNumber: "",
        type: value,
        issuerState: "",
        number: "",
      },
    }));
  };

  const renderIdentificationFields = () => {
    if (formValues.identificationData.type === "driversLicense") {
      return (
        <>
          <TextField
            label="Driver Licence Card Number"
            name="identificationData.cardNumber"
            value={formValues.identificationData.cardNumber}
            onChange={handleFormChange}
            fullWidth
            required
          />
          <TextField
            label="Driver Licence Issuer State"
            name="identificationData.issuerState"
            value={formValues.identificationData.issuerState}
            onChange={handleFormChange}
            fullWidth
            select
            required
          >
            <MenuItem value="NSW">New South Wales</MenuItem>
            <MenuItem value="VIC">Victoria</MenuItem>
            <MenuItem value="QLD">Queensland</MenuItem>
            <MenuItem value="WA">Western Australia</MenuItem>
            <MenuItem value="SA">South Australia</MenuItem>
            <MenuItem value="TAS">Tasmania</MenuItem>
            <MenuItem value="ACT">Australian Capital Territory</MenuItem>
            <MenuItem value="NT">Northern Territory</MenuItem>


          </TextField>
          <TextField
            label="Driver Licence Number"
            name="identificationData.number"
            value={formValues.identificationData.number}
            onChange={handleFormChange}
            fullWidth
            required
          />
        </>
      );
    } else if (formValues.identificationData.type === "passport") {
      return (
        <>
          <TextField
            label="Passport Number"
            name="identificationData.number"
            value={formValues.identificationData.number}
            onChange={handleFormChange}
            fullWidth
            required
          />
        </>
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Onboarding Adyen Trust</DialogTitle>
      <DialogContent>
        <form onSubmit={handleFormSubmit}>
          <AddressField onAddressChange={handleAddressChange} style={{ position: 'relative', zIndex: 0 }} />
          <TextField
            label="Country"
            name="country"
            value={formValues.country}
            onChange={handleFormChange}
            fullWidth
            required
            error={!!errors.country}
            helperText={errors.country}
          />
          <TextField
            label="Postal Code"
            name="postalCode"
            value={formValues.postalCode}
            onChange={handleFormChange}
            fullWidth
            required
            error={!!errors.postalCode}
            helperText={errors.postalCode}
          />
          <TextField
            label="Street"
            name="street"
            value={formValues.street}
            onChange={handleFormChange}
            fullWidth
            required
            error={!!errors.street}
            helperText={errors.street}
          />
          <TextField
            label="First Name"
            name="firstName"
            value={formValues.firstName}
            onChange={handleFormChange}
            fullWidth
            required
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <TextField
            label="Last Name"
            name="lastName"
            value={formValues.lastName}
            onChange={handleFormChange}
            fullWidth
            required
            error={!!errors.lastName}
            helperText={errors.lastName}
          />

          <TextField
            label="Date of Birth(YYYY-MM-DD)"
            name="dateOfBirth"
            value={formValues.dateOfBirth}
            onChange={handleFormChange}
            fullWidth
            required
            error={!!errors.dateOfBirth}
            helperText={errors.dateOfBirth}
          />
          <TextField
            label="Email"
            name="email"
            value={formValues.email}
            onChange={handleFormChange}
            fullWidth
            required
            error={!!errors.email}
            helperText={errors.email}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Identity Type</FormLabel>
            <RadioGroup
              row
              name="identificationType"
              value={formValues.identificationData.type}
              onChange={handleIdentityTypeChange}
            >
              <FormControlLabel
                value="driversLicense"
                control={<Radio />}
                label="Driver's License"
              />
              <FormControlLabel
                value="passport"
                control={<Radio />}
                label="Passport"
              />
            </RadioGroup>
          </FormControl>
          {renderIdentificationFields()}

          <DialogActions>
            <Button onClick={handleFormCancel} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AdyenTrustDialog;
